// @mui
import { Box, Container } from '@mui/material'
// components
import ClearNavbarContainer from '~/components/ClearNavbarContainer'

// section specific
import { HeroContentType, HeroLibraryForm } from './components'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingHero() {
  return (
    <ClearNavbarContainer
      id='hero-section'
      sx={{
        background:
          'linear-gradient(197.16deg, rgba(255, 255, 255, 0) 17.91%, rgba(95, 211, 247, 0.32) 59.28%, rgba(86, 232, 189, 0.14) 75.76%, rgba(75, 190, 195, 0.33) 88.2%);'
      }}
    >
      <Container variant='section'>
        <HeroContentType />

        <Box sx={{ marginTop: 4 }}>
          <HeroLibraryForm />
        </Box>
      </Container>
    </ClearNavbarContainer>
  )
}
