// @mui
import { Box } from '@mui/material'
// config
import { HEADER } from '~/config'

/**
 * @param {Object} props
 * @param {Object} props.sx
 * @returns {JSX.Element}
 */
export default function ClearNavbarContainer({ sx = {}, ...rest }) {
  return (
    <Box
      sx={{
        paddingTop: {
          xs: `${HEADER.MOBILE_HEIGHT}px`,
          md: `${HEADER.MAIN_DESKTOP_HEIGHT}px`
        },
        ...sx
      }}
      {...rest}
    />
  )
}
