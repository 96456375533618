// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material'
// components
import NextImage from '~/components/NextImage'

// images
import illustration from '~/assets/landing/business/illustration.png'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingBusiness() {
  const { _ } = useLingui()
  return (
    <Box
      id='business-section'
      sx={{
        background:
          'linear-gradient(197.16deg, rgba(207, 69, 255, 0.054) 17.91%, rgba(76, 113, 243, 0.132) 31.09%, rgba(81, 234, 204, 0.118649) 44.64%, rgba(95, 211, 247, 0.192) 58.55%, rgba(86, 232, 189, 0.084) 74.29%, rgba(75, 190, 195, 0.198) 88.2%);'
      }}
    >
      <Container variant='section'>
        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{ order: { xs: 0, md: 2 } }}
          >
            <Card>
              <Box sx={{ padding: 2 }}>
                <NextImage
                  src={illustration}
                  alt='clicky business illustration'
                  nextHeight={360}
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Box>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
          >
            <Stack spacing={3}>
              <Typography variant='h2'>
                🚀 {_(msg` Jalankan kerajaan bisnismu dengan skillmu.`)}
              </Typography>

              <Typography>
                {_(
                  msg`Jangan biarkan coding yang rumit dan algoritma yang ambigu menghentikan kamu untuk mendapatkan penghasilan yang berkelanjutan. Kami membuat penjualan menjadi sederhana.`
                )}
              </Typography>

              <div>
                <Button
                  variant='contained'
                  color='monochrome'
                  size='large'
                >
                  {_(msg`Buat Gratis`)}
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
