// @mui
import { Box, Card, Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import { GridCarousel } from '~/components/carousel'
// hooks
import useResponsive from '~/hooks/useResponsive'
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ContentChip = styled('span')({
  display: 'inline-block',
  padding: '0.15rem 0.5rem',
  borderRadius: '2rem',
  fontSize: '0.66rem'
})

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingCategory() {
  const mdUp = useResponsive('up', 'md')
  const { _ } = useLingui()

  const categories = [
    {
      name: _(msg`📚 Pendidikan`),
      color: '#DFF3F7',
      contents: [
        _(msg`Webinar`),
        _(msg`Kursus Video`),
        _(msg`Buku Audio`),
        _(msg`E-book`)
      ]
    },
    {
      name: _(msg`🧠 Ide`),
      color: '#E7E1EF',
      contents: [
        _(msg`Artikel`),
        _(msg`Talkshow`),
        _(msg`Konsultasi`),
        _(msg`Podcast`)
      ]
    },
    {
      name: _(msg`🎨 Seni & Desain`),
      color: '#DFF4D9',
      contents: [
        _(msg`Komik`),
        _(msg`Novel Grafis`),
        _(msg`Aset Grafis`),
        _(msg`Layanan`)
      ]
    },
    {
      name: _(msg`✍️ Menulis`),
      color: '#FDF7BD',
      contents: [
        _(msg`Novel`),
        _(msg`Buku Audio`),
        _(msg`Cerita Pendek`),
        _(msg`E-book`)
      ]
    },
    {
      name: _(msg`📸 Kamera`),
      color: '#FDE3BC',
      contents: [
        _(msg`Dokumenter`),
        _(msg`Film Pendek`),
        _(msg`Stock Photo`),
        _(msg`Preset`)
      ]
    },
    {
      name: _(msg`😎 Influencer`),
      color: '#D9F4F2',
      contents: [
        _(msg`Tipping`),
        _(msg`Tutorial How-To`),
        _(msg`Konten Eksklusif`),
        _(msg`Channel`)
      ]
    },
    {
      name: _(msg`💎️ Freelancer`),
      color: '#F8D8F1',
      contents: [
        _(msg`Template`),
        _(msg`Layanan`),
        _(msg`Aset Digital`),
        _(msg`Stok`)
      ]
    },
    {
      name: _(msg`👋 Komunitas`),
      color: '#F1EAEA',
      contents: [
        _(msg`Acara`),
        _(msg`Donasi`),
        _(msg`Merchandise`),
        _(msg`Fundraising`)
      ]
    },
    {
      name: _(msg`🔊 Audio`),
      color: '#F2FBCE',
      contents: [
        _(msg`Podcast`),
        _(msg`Buku Audio`),
        _(msg`Instrumental`),
        _(msg`Album`)
      ]
    },
    {
      name: _(msg`💻 Perangkat Lunak`),
      color: '#F4E1E1',
      contents: [
        _(msg`Tutorial`),
        _(msg`Template`),
        _(msg`Plugins`),
        _(msg`Kode & Skrip`)
      ]
    }
  ]

  return (
    <Box
      id='category-section'
      sx={{
        background:
          'linear-gradient(197.16deg, rgba(207, 69, 255, 0.054) 17.91%, rgba(76, 113, 243, 0.132) 31.09%, rgba(81, 234, 204, 0.118649) 44.64%, rgba(95, 211, 247, 0.192) 58.55%, rgba(86, 232, 189, 0.084) 74.29%, rgba(75, 190, 195, 0.198) 88.2%);'
      }}
    >
      <Container variant='section'>
        <Typography
          variant='h2'
          sx={{ marginBottom: 2 }}
        >
          💎 {_(msg` Buat Sekali, Jual Selamanya.`)}
        </Typography>

        <GridWrapper mdUp={mdUp}>
          {categories.map(({ name, color, contents }) => (
            <GridItemWrapper
              key={name}
              mdUp={mdUp}
            >
              <Card sx={{ height: '100%' }}>
                <Box sx={{ padding: 2 }}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      fontSize: { xs: '1rem', md: '1.25rem' },
                      fontWeight: 'bold'
                    }}
                  >
                    {name}
                  </Typography>

                  <Grid
                    container
                    spacing={0.5}
                  >
                    {contents.map((content) => (
                      <Grid
                        key={content}
                        item
                        xs='auto'
                      >
                        <ContentChip sx={{ backgroundColor: color }}>
                          {content}
                        </ContentChip>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Card>
            </GridItemWrapper>
          ))}
        </GridWrapper>
      </Container>
    </Box>
  )
}

const GridWrapper = ({ mdUp, children }) => {
  return mdUp ? (
    <Grid
      container
      spacing={2}
      columns={5}
      sx={{ alignItems: 'stretch' }}
    >
      {children}
    </Grid>
  ) : (
    <GridCarousel
      container
      spacing={2}
      columns={2}
      peek={0.15}
      sx={{ alignItems: 'stretch' }}
    >
      {children}
    </GridCarousel>
  )
}

const GridItemWrapper = ({ mdUp, children }) => {
  return mdUp ? (
    <Grid
      item
      xs={1}
    >
      {children}
    </Grid>
  ) : (
    <Box sx={{ height: '100%' }}>{children}</Box>
  )
}
