// @mui
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
// utils
import cssStyles from '~/libs/cssStyles'
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const PricingCard = styled(Card)({
  height: '100%'
})

const PricingCardTitle = styled('p')({
  fontWeight: 'bold'
})

const PricingCardPrice = styled('p')(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem'
  }
}))

const PricingCardDescription = styled('p')({
  fontSize: 'small'
})

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingPricing() {
  const theme = useTheme()
  const { _ } = useLingui()

  const HOW_IT_WORK_STEPS = [
    {
      title: _(msg`🍅 QRIS atau E-wallet`),
      price: '5%',
      description: _(msg`dari nilai transaksi. Dikenakan kepada pembeli.`)
    },
    {
      title: _(msg`🍊 Transfer Bank`),
      price: 'IDR 6,5K',
      description: _(msg`harga tetap. Dikenakan kepada pembeli.`)
    },
    {
      title: _(msg`🍏 Video`),
      price: '+5%',
      description: _(msg`biaya platform tambahan untuk konten Video`)
    },
    {
      title: '🍇 File/Audio',
      price: '+2%',
      description: _(msg`biaya platform tambahan untuk konten File/Audio.`)
    },
    {
      title: _(msg`🍋 Penarikan`),
      price: 'IDR 5K',
      description: _(msg`harga tetap. Hanya dikenakan pada penarikan kamu.`)
    }
  ]

  return (
    <Container
      variant='section'
      id='pricing-section'
    >
      <Box
        sx={{
          maxWidth: '120ch',
          marginX: 'auto'
        }}
      >
        <Typography
          component='h2'
          variant='h2'
          sx={{ textAlign: 'center' }}
        >
          💸{' '}
          <Typography
            variant='h2'
            component='span'
            sx={{
              ...cssStyles(theme).textGradient({
                direction: 'right',
                startColor: theme.palette.primary.main,
                endColor: theme.palette.secondary.main
              })
            }}
          >
            {_(
              msg`Harga? Kami hanya menarik fee jika kamu mendapat pemasukan.`
            )}
          </Typography>
        </Typography>
      </Box>

      <Typography sx={{ marginBottom: 3, textAlign: 'center' }}>
        {_(
          msg`Tidak ada biaya bulanan / biaya tiering. Kami tidak membatasimu untuk mendapatkan yang terbaik dari kami.`
        )}
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center', marginBottom: 8 }}
      >
        {HOW_IT_WORK_STEPS.map(({ title, price, description }) => (
          <Grid
            key={`price-card-${title}`}
            item
            xs={6}
            md={2.4}
            sx={{ alignSelf: 'stretch' }}
          >
            <PricingCard>
              <CardContent>
                <PricingCardTitle>{title}</PricingCardTitle>
                <PricingCardPrice>{price}</PricingCardPrice>

                <PricingCardDescription>{description}</PricingCardDescription>
              </CardContent>
            </PricingCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
