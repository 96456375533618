// next
import NextImage from '~/components/NextImage'
// @mui
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
// utils
import cssStyles from '~/libs/cssStyles'
// i18n
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// content type images
import eventIcon from '~/assets/landing/content-type/event.png'
import courseIcon from '~/assets/landing/content-type/course.png'
import videoIcon from '~/assets/landing/content-type/video.png'
import ebookIcon from '~/assets/landing/content-type/ebook.png'
import articleIcon from '~/assets/landing/content-type/article.png'
import comicIcon from '~/assets/landing/content-type/comic.png'
import artAndDesignIcon from '~/assets/landing/content-type/art-and-design.png'
import novelIcon from '~/assets/landing/content-type/novel.png'
import tipAndGiftIcon from '~/assets/landing/content-type/tip-and-gift.png'
import photoIcon from '~/assets/landing/content-type/photo.png'
import audioIcon from '~/assets/landing/content-type/audio.png'
import donationIcon from '~/assets/landing/content-type/donation.png'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const FeatureCardImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  borderRadius: '50%',
  background: 'white'
})

const FeatureCardInner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%'
})

const FeatureCardImage = styled(NextImage)({
  width: '60%',
  height: '60%',
  objectFit: 'contain',
  objectPosition: 'center center'
})

const FeatureCardText = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontSize: '0.6rem',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0.75),
    fontSize: '0.75rem'
  }
}))

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HeroContentType() {
  const theme = useTheme()
  const { _ } = useLingui()

  const contentTypes = [
    {
      alt: 'event icon',
      src: eventIcon,
      text: _(msg`Acara`)
    },
    {
      alt: 'course icon',
      src: courseIcon,
      text: _(msg`Kursus`)
    },
    {
      alt: 'video icon',
      src: videoIcon,
      text: 'Video'
    },
    {
      alt: 'ebook icon',
      src: ebookIcon,
      text: 'E-book'
    },
    {
      alt: 'article icon',
      src: articleIcon,
      text: _(msg`Artikel`)
    },
    {
      alt: 'comic icon',
      src: comicIcon,
      text: _(msg`Komik`)
    },
    {
      alt: 'art and design icon',
      src: artAndDesignIcon,
      text: _(msg`Seni & Desain`)
    },
    {
      alt: 'novel icon',
      src: novelIcon,
      text: 'Novel'
    },
    {
      alt: 'tip & gift icon',
      src: tipAndGiftIcon,
      text: _(msg`Tip & Hadiah`)
    },
    {
      alt: 'photo icon',
      src: photoIcon,
      text: _(msg`Foto`)
    },
    {
      alt: 'audio icon',
      src: audioIcon,
      text: 'Audio'
    },
    {
      alt: 'donationIcon',
      src: donationIcon,
      text: _(msg`Donasi`)
    }
  ]
  return (
    <Stack spacing={4}>
      <div>
        <Box
          sx={{
            maxWidth: '100ch',
            marginX: 'auto',
            textAlign: 'center'
          }}
        >
          <Typography
            variant='h1'
            sx={{
              ...cssStyles(theme).textGradient({
                direction: 'right',
                startColor: theme.palette.primary.main,
                endColor: theme.palette.secondary.main
              }),
              fontSize: { xs: '3rem', md: '4rem' },
              lineHeight: 1.15
            }}
          >
            <Trans>Jual Skillmu.</Trans>
          </Typography>

          <Typography sx={{ fontSize: '1.0rem' }}>
            {_(
              msg`Platform untuk jual produk digital, kelas, event, traktiran & lainnya dengan mudah. 🔥`
            )}
          </Typography>
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%', md: '100%' },
            marginX: 'auto'
          }}
        >
          <Grid
            container
            columns={{ xs: 4, sm: 6, md: 12 }}
            spacing={{ xs: 1.5, md: 3 }}
            sx={{ justifyContent: 'center' }}
          >
            {contentTypes.map(({ src, alt, text }) => (
              <Grid
                key={`content-type-${text}`}
                item
                xs={1}
              >
                <FeatureCardImageWrapper>
                  <FeatureCardInner>
                    <FeatureCardImage
                      src={src}
                      alt={alt}
                      nextWidth={64}
                      nextHeight={64}
                      priority
                    />
                  </FeatureCardInner>
                </FeatureCardImageWrapper>

                <FeatureCardText>{text}</FeatureCardText>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </Stack>
  )
}
