// @mui
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
// components
import { GridCarousel } from '~/components/carousel'
import NextImage from '~/components/NextImage'
// utils
import cssStyles from '~/libs/cssStyles'
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// images
import createYourPage from '~/assets/landing/how-it-works/create-your-page.png'
import uploadContent from '~/assets/landing/how-it-works/upload-content.png'
import earnMoney from '~/assets/landing/how-it-works/earn-money.png'
import shareContent from '~/assets/landing/how-it-works/share-content.png'
import screenshot from '~/assets/landing/how-it-works/screenshot.png'
import useResponsive from '~/hooks/useResponsive'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const StepCard = styled(Card)({
  height: '100%',
  background:
    'linear-gradient(180deg, rgba(5, 234, 155, 0.05) 0%, rgba(72, 232, 242, 0.04) 36.98%, rgba(16, 169, 175, 0.08) 100%);'
})

const StepCardTitle = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: 'large',
  fontWeight: 'bold'
}))

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingHowItWorks() {
  const theme = useTheme()

  const mdUp = useResponsive('up', 'md')

  const { _ } = useLingui()

  const howItWorkSteps = [
    {
      icon: createYourPage,
      title: _(msg`#1 Buat Halamanmu`),
      description: _(
        msg`Buat halaman webmu sendiri dengan mudah. Tidak diperlukan kode dan tidak ada biaya.`
      )
    },
    {
      icon: uploadContent,
      title: _(msg`#2 Upload Konten`),
      description: _(
        msg`Buat dan upload konten kamu: dari link, file, video, gambar, dan lainnya.`
      )
    },
    {
      icon: earnMoney,
      title: _(msg`#3 Dapatkan Uang`),
      description: _(
        msg`Dorong lalu lintas dan konversi di halaman kamu. Jual keahlianmu!`
      )
    },
    {
      icon: shareContent,
      title: _(msg`#4 Bagikan Konten`),
      description: _(
        msg`Kemudian, pembelimu dapat mengakses kontenmu secara otomatis.`
      )
    }
  ]

  return (
    <Container
      variant='section'
      id='how-it-works-section'
    >
      <Typography
        component='h2'
        variant='h2'
        sx={{ marginBottom: 3, textAlign: 'center' }}
      >
        😎{' '}
        <Typography
          variant='h2'
          component='span'
          sx={{
            ...cssStyles(theme).textGradient({
              direction: 'right',
              startColor: theme.palette.primary.main,
              endColor: theme.palette.secondary.main
            })
          }}
        >
          {_(msg`Bagaimana Cara Kerjanya? Mudah.`)}
        </Typography>
      </Typography>

      <GridCarousel
        container
        columns={{ xs: 1, md: 4 }}
        spacing={{ xs: 2, md: 4 }}
        peek={mdUp ? 0 : 0.15}
        sx={{ marginBottom: 8 }}
      >
        {howItWorkSteps.map(({ title, description, icon }) => (
          <StepCard key={`how-it-works-${title}`}>
            <CardContent>
              <NextImage
                src={icon}
                alt='title icon'
                nextHeight={64}
                nextWidth={64}
                sx={{
                  width: '4rem',
                  height: '4rem',
                  marginBottom: 2,
                  objectFit: 'contain',
                  objectPosition: 'bottom'
                }}
              />

              <StepCardTitle>{title}</StepCardTitle>

              <Typography sx={{ fontSize: { xs: 'small', md: 'medium' } }}>
                {description}
              </Typography>
            </CardContent>
          </StepCard>
        ))}
      </GridCarousel>

      <Grid
        container
        spacing={{ xs: 2, md: 8 }}
      >
        <Grid
          item
          xs={12}
          md={5}
        >
          <Card>
            <NextImage
              src={screenshot}
              alt='clicky page screenshot'
              nextHeight={360}
              sx={{ width: '80%', height: 'auto', marginX: 'auto' }}
            />
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
        >
          <Stack spacing={3}>
            <Typography variant='h2'>
              🔥
              {_(msg`Buat halaman web yang mudah, interaktif & menjual.`)}
            </Typography>

            <Typography>
              {_(
                msg`Jangan terjebak dengan tombol yang membosankan. Keahlian, audiens & masa depan bisnis kamu layak mendapat lebih.`
              )}
            </Typography>

            <Stack spacing={1}>
              <Typography>
                ✅ {_(msg`Template sederhana namun cantik.`)}
              </Typography>

              <Typography>
                ✅{' '}
                {_(
                  msg`Memungkinkan kamu mempromosikan keahlianmu dengan berbagai cara.`
                )}
              </Typography>

              <Typography>
                ✅{' '}
                {_(
                  msg`Berfokus pada konversi. Membantumu menjual lebih mudah.`
                )}
              </Typography>
            </Stack>

            <div>
              <Button
                variant='contained'
                color='monochrome'
                size='large'
              >
                {_(msg`Buat Gratis`)}
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
