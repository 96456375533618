// @mui
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
// components
import NextImage from '~/components/NextImage'
import { GridCarousel } from '~/components/carousel'
// hooks
import useResponsive from '~/hooks/useResponsive'
// utils
import cssStyles from '~/libs/cssStyles'

// feature illustration
import paymentReminderIllustration from '~/assets/landing/feature/payment-reminder.png'
import promoteContentIllustration from '~/assets/landing/feature/promote-content.png'
import paymentGatewayIllustration from '~/assets/landing/feature/payment-gateway.png'
import customizeLinkIllustration from '~/assets/landing/feature/customize-link.png'
import completeAnalyticsIllustation from '~/assets/landing/feature/complete-analytics.png'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const FeatureCardWrapperDesktop = styled('div')({
  height: '100%',
  '& > *': {
    height: '100%'
  }
})

const FeatureCardWrapperMobile = styled('div')({
  height: '100%',
  '& > *': {
    height: '100%'
  }
})

const FeatureCardDescription = styled(Typography)({
  fontWeight: 500,
  fontSize: '0.85rem',
  lineHeight: 1.4
})

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function LandingFeature() {
  const theme = useTheme()

  const mdUp = useResponsive('up', 'md')
  const { _ } = useLingui()

  const features = [
    {
      key: 'customize-link',
      illustration: customizeLinkIllustration,
      description: _(
        msg`Kustomisasi link dan Situsmu dengan Berbagai Template & Format.`
      )
    },
    {
      key: 'promote-content',
      illustration: promoteContentIllustration,
      description: _(
        msg`Promosikan dan Kelola Semua Jenis Konten. Gratis atau Berbayar.`
      )
    },
    {
      key: 'complete-analytics',
      illustration: completeAnalyticsIllustation,
      description: _(
        msg`Analitik Lengkap. Dari Sumber, Tampilan, Jumlah Klik hingga Transaksi.`
      )
    },
    {
      key: 'payment-gateway',
      illustration: paymentGatewayIllustration,
      description: _(
        msg`Terintegrasi dengan berbagai Metode Pembayaran yang Siap Digunakan.`
      )
    },
    {
      key: 'payment-reminder',
      illustration: paymentReminderIllustration,
      description: _(msg`Pengingat dan Konfirmasi Pembayaran Otomatis.`)
    }
  ]

  return (
    <Container
      id='feature-section'
      variant='section'
    >
      <Stack spacing={4}>
        <Box
          sx={{
            maxWidth: '120ch',
            marginX: 'auto'
          }}
        >
          <Typography
            component='h2'
            sx={{ textAlign: 'center' }}
          >
            <Typography
              variant='h2'
              component='span'
              sx={{ marginRight: '0.25em' }}
            >
              🎊
            </Typography>

            <Typography
              variant='h2'
              component='span'
              sx={{
                ...cssStyles(theme).textGradient({
                  direction: 'right',
                  startColor: theme.palette.primary.main,
                  endColor: theme.palette.secondary.main
                })
              }}
            >
              {_(msg`Dapatkan Semua Fitur Gratis`)}
            </Typography>
          </Typography>
        </Box>

        <div>
          {mdUp ? (
            <Grid
              container
              spacing={4}
              columns={{ lg: 5 }}
              sx={{ jusitfyContents: 'center', alignItems: 'stretch' }}
            >
              {features.map(({ key, description, illustration }) => (
                <Grid
                  key={`feature-desktop-${key}`}
                  item
                  xs={1}
                >
                  <FeatureCardWrapperDesktop>
                    <Card variant='outlined'>
                      <CardMedia>
                        <NextImage
                          alt={description}
                          src={illustration}
                          nextHeight={180}
                          sx={{ width: '100%', height: 'auto' }}
                        />
                      </CardMedia>

                      <CardContent>
                        <FeatureCardDescription>
                          {description}
                        </FeatureCardDescription>
                      </CardContent>
                    </Card>
                  </FeatureCardWrapperDesktop>
                </Grid>
              ))}
            </Grid>
          ) : (
            <GridCarousel
              columns={{ xs: 1, md: 3 }}
              peek={mdUp ? 0 : 0.15}
            >
              {features.map(({ key, description, illustration }) => (
                <FeatureCardWrapperMobile key={`feature-mobile-${key}`}>
                  <Card variant='outlined'>
                    <CardMedia>
                      <NextImage
                        alt={description}
                        src={illustration}
                        nextHeight={240}
                        sx={{ width: '100%', height: 'auto' }}
                      />
                    </CardMedia>

                    <CardContent>
                      <FeatureCardDescription>
                        {description}
                      </FeatureCardDescription>
                    </CardContent>
                  </Card>
                </FeatureCardWrapperMobile>
              ))}
            </GridCarousel>
          )}
        </div>
      </Stack>
    </Container>
  )
}
