// layouts
import Layout from '~/layouts'
// components
import Page from '~/components/Page'

// page specific
import {
  LandingHero,
  LandingFeature,
  LandingHowItWorks,
  LandingCategory,
  LandingBusiness,
  LandingPricing
} from '~/sections/landing'

// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// ----------------------------------------------------------------------

LandingPage.getLayout = function getLayout(page) {
  return (
    <Layout
      variant='main'
      isClearNavbar={false}
      isClearFooter={false}
    >
      {page}
    </Layout>
  )
}

// ----------------------------------------------------------------------

// i18n only getStaticProps
export { getStaticProps } from '~/i18n/utils'

/**
 * @returns {JSX.Element}
 */
export default function LandingPage() {
  const { _ } = useLingui()
  return (
    <Page title={_(msg`Tunjukkan Skillmu`)}>
      <LandingHero />

      <LandingHowItWorks />

      <LandingBusiness />

      <LandingFeature />

      <LandingCategory />

      <LandingPricing />
    </Page>
  )
}
