// react
import { useEffect } from 'react'
// next
import NextLink from 'next/link'
// @mui
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
// components
import { FormProvider, RHFTextField } from '~/components/hook-form'
// hooks
import { useSnackbar } from 'notistack'
import { useUserLibrary } from '~/swr/library'
import useAuth from '~/hooks/useAuth'
// validators
import { CatalogLibrarySchema } from '~/validators'
// service
import { libraryService } from '~/services'
// config
import { FE_HOSTNAME } from '~/config'
// paths
import { PATH_APP, PATH_AUTH } from '~/routes/paths'
// i18n
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HeroLibraryForm() {
  const { isAuthenticated, isInitialized } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useRouter()
  const { _, i18n } = useLingui()

  const { data: library, isLoading: isLibraryLoading } = useUserLibrary()

  // generate psuedo form to generate certificate
  const methods = useForm({
    resolver: yupResolver(CatalogLibrarySchema(i18n)),
    defaultValues: {
      basePath: ''
    }
  })

  const {
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful }
  } = methods

  const handleSaveOrUpdate = async (values) => {
    try {
      const isBasePathAvailable = await libraryService
        .getByIdOrBasePath(values.basePath)
        .then(() => false)
        .catch((err) => {
          if (err.response.status === 404) return true

          throw err
        })

      if (!isBasePathAvailable) {
        enqueueSnackbar('Link clicky sudah digunakan!', { variant: 'error' })

        return
      }

      push({
        pathname: PATH_AUTH.signUp,
        query: {
          next: PATH_AUTH.verifyEmail(
            `${PATH_APP.onboarding.createLink}?basePath=${values.basePath}&isAutoSubmit=true`
          )
        }
      })
    } catch (err) {
      const errorMessage = err?.response?.data?.message

      setError('basePath', {
        message:
          errorMessage ||
          _(
            msg`Terjadi kesalahan saat menyimpan link Clicky, silahkan coba lagi dalam beberapa saat.`
          )
      })

      if (!errorMessage) console.error(err)
    }
  }

  useEffect(() => {
    if (!library) return

    setValue('basePath', library.basePath)
  }, [library])

  // accumulator variable
  const isReady =
    isInitialized && !isLibraryLoading && !isSubmitting && !isSubmitSuccessful
  const isLibraryExist = Boolean(isAuthenticated && library)

  return (
    <div>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(handleSaveOrUpdate)}
      >
        <Box sx={{ marginBottom: 1.5, textAlign: 'center' }}>
          <Typography>
            <Trans>
              🚀 Buat website tanpa coding dan mulai jual produkmu. Gratis
              selamanya.
            </Trans>
          </Typography>
        </Box>

        <Box sx={{ maxWidth: '60ch', marginX: 'auto' }}>
          <Grid
            container
            spacing={2}
            sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
          >
            <Grid
              item
              xs={12}
              md
            >
              <RHFTextField
                name='basePath'
                placeholder='...'
                size='large'
                disabled={!isReady}
                readOnly={isLibraryExist}
                InputProps={{
                  sx: { background: 'white' },
                  startAdornment: (
                    <InputAdornment
                      position='start'
                      sx={{ marginRight: 0 }}
                    >
                      {FE_HOSTNAME}/
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md='auto'
            >
              <LoadingButton
                LinkComponent={isLibraryExist ? NextLink : undefined}
                href={isLibraryExist ? PATH_APP.catalog.update : undefined}
                type={isLibraryExist ? 'button' : 'submit'}
                variant='contained'
                size='large'
                fullWidth
                loading={isSubmitting}
                disabled={!isReady || isSubmitSuccessful}
                color='monochrome'
              >
                {isLibraryExist
                  ? _(msg`Kunjungi Dashboard`)
                  : _(msg`Buat Halamanmu`)}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </div>
  )
}
